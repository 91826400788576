// import axios, { AxiosError } from 'axios';

// const calculateApi = async (data) => {
//   try {
//     const apiClient = axios.create({
//       baseURL: 'https://localhost:44338/',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });

//     const response = await apiClient.post('/Hesap', data, {
//       params: {
//         key: '0DA5041D-9661-405D-A3D6-F1709F267237',
//       },
//     });

//     return response.data;
//   } catch (error) {
//     if (error instanceof AxiosError) {
//       console.error('API Hatası:', error.response?.data || error.message);
//       throw new Error(`API Hatası: ${error.response?.data?.message || error.message}`);
//     } else {
//       console.error('Beklenmedik Hata:', error.message);
//       throw new Error(`Beklenmedik Hata: ${error.message}`);
//     }
//   }
// };

// export { calculateApi };

//localde test için eklendi
import axios from 'axios';
const apiClient = axios.create({
  baseURL: 'https://komisyonhesapla.pazaryericonverter.com/',
  headers: {
    'Content-Type': 'application/json',  // JSON formatında veri gönderiliyor
  },
});

const calculateApi = async (formData) => {
  try {
    // Axios isteği yapılırken formData doğrudan gönderiliyor
    const response = await apiClient.post('/Hesap/?key=0DA5041D-9661-405D-A3D6-F1709F267237', formData);
    if(response)
    return response.data;
  } catch (error) {
    console.error("Error making API call:", error.response ? error.response.data : error.message);
    throw error;
  }
};

export { calculateApi };

